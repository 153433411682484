<!-- AdminCareSeekers.vue -->
<template>
  <div class="main-table-container">
    <h2>Care givers</h2>
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManagerVue ref="toastManager" />
    <div class="careseekers-table-container">
      <!-- ...other components like filters... -->
      <UniversalTable :endpoint="endpoint" :columns="columns">
        <!-- Custom cell rendering using scoped slots -->
        <template #cell-approval_status="{ row }">
          <span :class="row.approval_status == 1 ? 'active' : 'inactive'">
            {{ row.approval_status == 1 ? "Active" : "Inactive" }}
          </span>
        </template>

        <template #cell-action="{ row, index }">
          <div class="action-cell-container">
            <span class="icon-dots" @click="toggleDelistDropdown(index)">
              <svg
                width="16"
                height="4"
                viewBox="0 0 16 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                  fill="black"
                />
              </svg>
            </span>
            <div v-if="actionDropdown === index" class="dropdown-menu">
              <button v-if="row.approval_status===1" @click="delistCaregiver(row.id)">
                <span class="icon"
                  ><svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM1.6 8C1.6 4.464 4.464 1.6 8 1.6C9.48 1.6 10.84 2.104 11.92 2.952L2.952 11.92C2.104 10.84 1.6 9.48 1.6 8ZM8 14.4C6.52 14.4 5.16 13.896 4.08 13.048L13.048 4.08C13.896 5.16 14.4 6.52 14.4 8C14.4 11.536 11.536 14.4 8 14.4Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Delist caregiver
              </button>
              <button v-if="row.approval_status===2" @click="listCaregiver(row.id)">
                <span class="icon"
                >
                <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
              </span>
              List caregiver
            </button>
            </div>
          </div>
        </template>
      </UniversalTable>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import UniversalTable from "@/components/UniversalTable.vue";
import Request from "@/utils/apiclient.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import { formatErrorMessages } from "@/utils/errorHandler";

export default {
  name: "AdminCareGivers",
  components: {
    UniversalTable,
    LoadingSpinner,
    ToastManagerVue,
  },
  setup() {
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const isLoading = ref(false);

    const actionDropdown = ref(null);

    const endpoint = "/admin/care-givers/";

    const columns = [
      { label: "ID", field: "id" },
      {
        label: "Name",
        field: "name",
        render: (row) => `${row.user.first_name} ${row.user.last_name}`,
      },
      { label: "Category", field: "category.name" },
      { label: "Status", field: "approval_status" },

      { label: "Action", field: "action" },
    ];

    // Functions for filters, actions, etc.


    const toggleDelistDropdown = (index) => {
      console.log("I got clicked", index);
      console.log(actionDropdown.value);
      actionDropdown.value = actionDropdown.value === index ? null : index;
    };

    const delistCaregiver = (id) => {
      handleDelist(id);
      actionDropdown.value = null;
    };

    const listCaregiver = (id) => {
      handleList(id);
      actionDropdown.value = null;
    };
    

    const handleDelist = async (id) => {
      isLoading.value = true;
      const formData = {
        approval_status: 2,
      };
      const request = new Request(`/admin/care-givers/${id}/`);
      const rawData = JSON.stringify(formData);
      request.patch(rawData, (data, error) => {
        isLoading.value = false;
        if (error) {
          if (error.status == "failure") {
            return showToast(error.message, "error");
          }
          if (error.detail) {
            return showToast("Error delisting careseeker", "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          showToast("Careseeker delisted successfully", "success");
          // Optionally, refetch data or update local state
        }
      });
    };

    const handleList = async (id) => {
      actionDropdown.value = null;
      isLoading.value = true;
      const formData = {
        approval_status: 1,
      };
      const request = new Request(`/admin/care-givers/${id}/`);
      const rawData = JSON.stringify(formData);
      request.patch(rawData, (data, error) => {
        isLoading.value = false;
        if (error) {
          console.log("error");
          if (error.status == "failure") {
            return showToast(error.message, "error");
          }
          if (error.detail) {
            return showToast("Error listing caregiver", "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("success");
          showToast("Caregiver listed successfully", "success");
          // Optionally, refetch data or update local state
        }
      });
    };

    return {
      endpoint,
      columns,
      isLoading,
      actionDropdown,
      toggleDelistDropdown,
      delistCaregiver,
      listCaregiver,
      showToast,
      toastManager,
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>

<style scoped>
.main-table-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 1008px;
  margin-top: 52px;
}

.filter-container {
  margin-bottom: 20px;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 340px;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #979797;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}

.custom-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 340px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-top: 8px;
  z-index: 10;
  list-style-type: none;
  padding: 0;
}

.custom-options li {
  padding: 8px;
  cursor: pointer;
}

.custom-options li:hover,
.custom-options li.selected {
  background-color: #f4f4f4;
}

.careseekers-table {
  width: 100%;

  border-spacing: 0 13px;
  /* opacity: 0.5; */
}
.careseekers-table-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  padding: 24px;
  background-color: #ececec80;
  min-height: 772px;
  margin-bottom: 400px;
}

.careseekers-table th {
  /* opacity: 1; */
  padding: 8px;

  font-size: 16px;
  font-weight: 500;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table td {
  padding: 8px;

  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table th {
  background-color: #f4f4f4;
}

.careseekers-table td.active {
  width: 70px;
  height: 35px;
  background-color: var(--state-success);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.careseekers-table td.inactive {
  width: 83px;
  height: 35px;
  padding: 8px;
  background-color: #979797;
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.action-cell-container {
  position: relative; /* Creates a positioning context for absolute children */
}

.dropdown-menu {
  position: absolute;
  right: 60px; /* Align to the right edge of the icon-dots container */
  top: -30px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #0000001a;
  padding: 10px;
  transform: translateY(100%); /* Position below the icon-dots */
  z-index: 10;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  text-align: left;
}

.dropdown-menu .icon {
  margin-right: 8px;
}

.selected-option {
  display: flex;
  align-items: center;
}

.selected-option svg {
  margin-right: 8px;
}
.the-td {
  position: relative;
}
.icon-dots {
  display: flex;
  padding-left: 12px;

  cursor: pointer;
}
/* Pagination styles (commented out for now) */
/*
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
*/
h2 {
  margin-top: 50px;
}
@media (max-width: 800px) {
  .main-table-container {
    width: 280px;
  }
  .careseekers-table {
    border-spacing: 0px 5px;
  }

  .careseekers-table td.active {
    width: 30px;
    height: 25px;
    background-color: var(--state-success);
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .careseekers-table td.inactive {
    width: 33px;
    height: 25px;
    padding: 8px;
    background-color: #979797;
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .careseekers-table th {
    /* opacity: 1; */
    padding: 8px;

    font-size: 7px;
    font-weight: 500;
    line-height: 27.2px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  .careseekers-table td {
    padding: 8px;

    font-size: 7px;
    font-weight: 400;

    letter-spacing: 0.5px;
    text-align: left;
  }
  .custom-select-wrapper {
    width: 200px;
  }
}
</style>